.add-user-modal .password-box {
  position: relative;
}

.add-user-modal .password-box .password-btn-group{
  text-align: right;
  position: relative;
  top: -10px;
  right: 0;
  .ant-btn {
    margin-left: 10px;
  }

}
